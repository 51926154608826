@import "../../utils/var.scss";

@mixin banner_bgdark {
  padding: 40px 0px 50px;
  background: url("../../assets/service_bg.png") no-repeat;
  background-size: cover;
  background-position: bottom;
  border-radius: 0px 0px 100px 100px;
}

.home_banner_section {
  padding: 15px 0 0;
  .banner_content {
    p {
      font-size: $font-size-20;
      font-weight: $font-weight-400;
      margin-bottom: 10px;
    }
    h2 {
      font-size: $font-size-55;
      font-weight: $font-weight-900;
    }
    h5 {
      font-family: $font-family;
      font-size: $font-size-25;
      line-height: normal;
      margin-top: $margin_bottom;
      margin-bottom: $margin_bottom_35;
    }
    a {
      @include header-button;
      padding: 5px 5px 5px 20px;
      img {
        transform: rotate(90deg);
        background: #fff;
        padding: 10px;
        border-radius: 50%;
        animation: none;
        margin-left: 10px;
      }
    }
  }
  .banner_img {
    img {
      margin-bottom: 0px;
      // animation: topbottom 2s infinite;
      width: 100%;
      height: 500px;
      object-fit: contain;
    }
  }
}

.about_banner_section {
  padding: 40px 0px 70px;
  h1 {
    @include large_title;
    text-shadow: 7px 7px 10px rgba(0, 0, 0, 0.15);
  }
  .banner_content {
    padding: 0px 0px 0px 140px;
    h2 {
      @include second_title;
      margin-bottom: 31px;
    }
    p {
      font-size: $font-size-20;
      line-height: $line-height-30;
      color: $text-555;
    }
  }
}

.services_banner_section {
  @include banner_bgdark;
  h1 {
    @include second_title;
    color: $white-color;
    font-weight: $font-weight-900;
    margin-bottom: $margin_bottom;
    margin: 0 auto 20px;
  }
  p {
    font-size: $font-size-30;
    line-height: 40px;
    color: $white-color;
    margin: 0 auto 40px;
  }
  img {
    animation: topbottom 1.5s infinite;
  }
}

.about_banner_section {
  &.work_banner_section, &.work_inner_banner {
    @include banner_bgdark;
    h2,
    p {
      color: $white-color;
    }
  }
  &.work_inner_banner {
    .banner_content {
      padding: 0px;
      h2 {
        margin: 0px;
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .home_banner_section {
    .banner_content {
      p {
        font-size: $font-size-18;
      }
      h2 {
        font-size: $font-size-45;
      }
      h5 {
        font-size: $font-size-20;
        line-height: normal;
      }
    }
    .banner_img {
      img {
        max-width: 100%;
      }
    }
  }
  .about_banner_section {
    .banner_content {
      padding: 0px 0px 0px 70px;
    }
  }
}

@media only screen and (max-width: $large-1200) {
  .home_banner_section {
    .banner_content {
      p {
        font-size: $font-size-16;
      }
      h2 {
        font-size: $font-size-35;
      }
      h5 {
        font-size: $font-size-18;
        margin-bottom: $margin_bottom;
      }
      a {
        img {
          padding: 8px;
        }
      }
    }
    .banner_img {
      img {
        height: auto;
      }
    } 
  }
  .about_banner_section {  
    .banner_content {
      h2 {
        margin-bottom: $margin_bottom;
      }
      p {
        font-size: $font-size-18;
        line-height: inherit;
      }
    }
    &.work_banner_section { 
      border-radius: 0px 0px 60px 60px;
    }
  }
  .services_banner_section {
    border-radius: 0px 0px 60px 60px;
    p {
      font-size: $font-size-25;
      line-height: normal;
    }
  } 
}

@media only screen and (max-width: $sm-tablet-991) {
  .home_banner_section {
    .banner_content {
      p {
        font-size: $font-size-15;
      }
      h2 {
        font-size: $font-size-25;
      }
      h5 {
        font-size: $font-size-16;
        margin-top: 10px;
      }
      a {
        font-size: $font-size-15;
        img {
          padding: 5px;
        }
      }
    }
  }
  .about_banner_section { 
    padding: 20px 20px 60px;
    .banner {
      flex-direction: column;
      .large-title {
        h1 {
          br {
            display: none;
          }
        }
      }
      .banner_content {
        padding: 0px;
        text-align: center;
        p {
          font-size: $font-size-16;
        }
      }
    }
    &.work_banner_section { 
      padding: 40px 20px;
    }
    &.work_inner_banner {
      border-radius: 0px 0px 60px 60px;
    }
  }
  .services_banner_section {
    padding: 30px 20px;
    p {
      font-size: $font-size-18;
      margin: 0 auto 25px;
    }
    img {
      width: 50px;
    }
  }
}

@media only screen and (max-width: $mobile-767) {
  .home_banner_section {
    padding: 20px;
    .banner {
      flex-direction: column-reverse;
      gap: 30px;
      .banner_content {
        text-align: center;
      }
      .banner_img {
        img {
          height: 280px;
        }
      }
    }
  }
  .about_banner_section.work_inner_banner {
      padding: 25px 0px;
      border-radius: 0px 0px 30px 30px;
  }
}

@media only screen and (max-width: $sm-mobile-575) {
  .home_banner_section {
    .banner_content {
      h5 {
        font-size: $font-size-15;
      }
    }
  }
  .about_banner_section {
    &.work_banner_section { 
      border-radius: 0px 0px 30px 30px;
    }
  }
  .services_banner_section {
    border-radius: 0px 0px 30px 30px;
    p {
      font-size: $font-size-16;
    }
    img {
      width: 40px;
    }
  }
}