@import "../../utils/var.scss";

@mixin badges-title {
  font-weight: $font-weight-900;
  font-size: $font-size-40;
  line-height: $line-height-45;
  color: $text-222;
}

.vision_mision_section {
  padding: 70px 0px;
  .mision-vision {
    padding: 90px;
    @include bg-radius;
    .first_column {
      .nav-tabs {
        border: 0px;
        li {
          button {
            background: #e2e2e2;
            border: 0px;
            border-radius: 15px;
            font-size: 20px;
            padding: 19px 30px;
            font-weight: 800;
            color: $text-222;
            margin-right: 8px;
            &.active {
              background: $theme-color;
              color: $white-color;
            }
          }
        }
      }
      h1 {
        @include second_title;
        margin-top: 16px;
        margin-bottom: $margin_bottom;
      }
      p {
        @include paragraph;
        margin-top: $margin_bottom;
        margin-bottom: $margin_bottom;
        padding-right: 50px;
      }
    }
    .second_column {
      position: relative;
      background: url('../../assets/svg/watermark.svg') no-repeat;
      background-position: left center;
      background-size: 60%;
      img {
        position: absolute;
        top: -50px;
        right: 0;
        animation: topbottom 1.5s infinite;
        width: 300px;
      }
    }
  }
}

.freelance_section {
  padding: 60px 0px;
  ._column {
    max-width: 1110px;
    margin-left: auto;
    position: relative;
    padding: 90px 0 0 100px;
    @include bg-radius;
    .profile {
      position: absolute;
      left: -280px;
      background: url('../../assets/badges/profile_bg.png') no-repeat;
      background-size: cover;
      width: 343px;
      height: 410px;
      @include flex-center;
      flex-direction: column;
      .profile_pic {
        width: 110px;
        border-radius: 50%
      }
      h2{
        @include badges-title;
        color: $white-color;
      }
      p{
        color: $white-color;
        font-size: $font-size-20;
        margin-bottom: $margin_bottom;
      }
    }
    .badges_ {
      .text_badges {
        h3 {
          @include badges-title;
          margin-bottom: 15px;
          .theme-color {
            color: $theme-color;
          }
        }
        &.active_reward {
          padding: 33px 0px;
          border-bottom: 1px solid #e0e0e0;
        }
        &.earn_badges {
          padding-top: 33px;
        }
        img{
          max-width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .freelance_section {
    ._column {
      padding: 50px;
      max-width: none;
      display: flex;
      gap: 30px;
      .profile {
        position: static;
        background-size: 100%;
        height: 350px;
        img {
          width: 200px;
        }
        h2 {
          font-size: $font-size-35;
          line-height: normal;
        }
        p {
          font-size: $font-size-18;
          margin-bottom: 10px;
        }
      }
      .badges_ {
        .text_badges {
          h3 {
            font-size: $font-size-35;
            line-height: normal;
          }
          &.earn_badges {
            padding-top: 20px;
          }
          &.active_reward {
            padding: 20px 0px;
          }
        } 
      }
    }
  }
}

@media only screen and (max-width: $large-1200) {
  .freelance_section {
    padding: 40px 5px;
    ._column  {
      padding: 30px;
      .profile {
        height: 260px;
        width: 300px;
        img {
          width: 180px;
        }
        h2 {
          font-size: $font-size-25;
        }
        .profile_pic {
          width: 80px;
        }
      } 
      .badges_ {
        .text_badges {
          h3 {
            font-size: $font-size-25;
          }
        }
      }
    }
  } 
}

@media only screen and (max-width: $sm-tablet-991) {
  .vision_mision_section {
    padding: 40px 5px;
    .mision-vision {
      padding: 40px;
      .row {
        flex-direction: column;
        .first_column {
          text-align: center;
          .nav-tabs {
            justify-content: center;
            li {
              button {
                font-size: $font-size-16;
                padding: 12px 20px;
              }
            }
          }
          p {
            padding: 0;
          }
        }
        .second_column {
          background-position: center;
          text-align: center;
          img {
            position: static;
            width: 220px;
          }
        }
      }
    }
  } 
  .freelance_section {
    ._column  {
      .profile {
        height: 240px;
        min-width: 200px;
        img {
          width: 150px;
          margin-bottom: 10px;
        }
        h2 {
          font-size: $font-size-20;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-767) {
  .vision_mision_section {
    .mision-vision {
      padding: 20px;
    }
  } 
  .freelance_section {
    ._column {
      flex-direction: column;
      align-items: center;
      padding: 12px;
      .profile {
        background-size: 100% 100%;
        height: 280px;
        min-width: 250px;
        width: 250px;
        p {
          font-size: $font-size-16;
        }
      }
      .badges_  {
        .text_badges  {
          h3 {
            font-size: $font-size-20;
          }
          &.active_reward {
            padding: 10px 0;
          }
        }
      }
  }
  }
}

@media only screen and (max-width: $sm-mobile-575) {
  .vision_mision_section {
    .mision-vision {
      padding: 10px;
    }
  } 
}