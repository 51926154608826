@import "../../utils/var.scss";

.digital_services_section {
  padding: 60px 0px;
  background-image: url(../../assets/background.jpg);
  h2 {
    @include second_title;
    text-align: center;
    margin-bottom: 50px;
  }
  .digital_services_row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    .digital_services_ {
      background-image: linear-gradient(white, white), linear-gradient(to right, #fdd9d9, #fdd9d9, $theme-color, #fdd9d9, #fdd9d9);
      background-clip: content-box, border-box;
      border: solid 1px transparent;
      border-radius: 20px;
      overflow: hidden;
      .digital_services_content {
        padding: 30px;
        background-color: $white-color;
        img {
          width: 70px;
          margin-bottom: 20px;
          filter: invert(17%) sepia(63%) saturate(5612%) hue-rotate(349deg) brightness(86%) contrast(87%);
        }
        h3 {
          font-size: $font-size-25;
          // font-weight: $font-weight-600;
          margin-bottom: $margin_bottom - 5;
          color: $theme-color;
        }
        p {
          @include paragraph;
          color: $text-555;
        }
      }
    }
  }
  @media only screen and (max-width: $large-1200) {
    padding: 40px 10px;
    h2 {
      margin-bottom: 30px;
    }
    .digital_services_row {
      gap: 20px;
      .digital_services_ {
        .digital_services_content {
          padding: 20px;
          h3 {
            margin-bottom: 10px;
            font-size: $font-size-20;
          }
        }
      }
    }
  }
  @media only screen and (max-width: $sm-tablet-991) {
    .digital_services_row {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px
    }
  }
  @media only screen and (max-width: $mobile-767) {
    h2 {
      margin-bottom: $margin_bottom;
    }
    .digital_services_row {
      grid-template-columns: repeat(1, 1fr);
      .digital_services_ {
        border-radius: 12px;
        .digital_services_content {
          h3 {
            font-size: $font-size-18;
          }
        }
      }
    }
  }
}

