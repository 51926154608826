@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;800;900&display=swap");
@import "~bootstrap/scss/bootstrap";
@import "./utils/Var.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Mulish";
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}
p {
  margin: 0px;
}
@keyframes topbottom {
  0%,
  100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-15px);
  }
}
@keyframes leftright {
  0%,
  100% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(10px);
  }
}
@keyframes scale {
  0%,
  100% {
    transform: scale(0.96);
    -webkit-transform: scale(0.96);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

// button component styling start
.second_button {
  @include second-button;
  img {
    padding-left: 17px;
    animation: leftright 1.6s ease-in-out infinite;
    -webkit-animation: leftright 1.6s ease-in-out infinite;
  }
  &:hover,
  &:active,
  &:focus {
    background: $text-000;
    box-shadow: none !important;
  }
  &.link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    text-decoration: none;
    &:hover {
      color: $white-color;
    }
  }
}
// button component styling end

.theme-color {
  color: $theme-color;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
}
