@import "../../utils/var.scss";

@mixin grid-layout {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  @media only screen and (max-width: $sm-tablet-991) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  @media only screen and (max-width: $sm-mobile-575) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

.logo_section {
  h2 {
    @include second_title;
    margin-bottom: $margin_bottom_40;
  }
  &.client_section {
    padding: 0px 0px 60px;
    img {
      width: 100%;
    }
    .logo_row {
      @include grid-layout;
      .logos {
        padding: 20px;
        border: 1px dashed #ddd;
        border-radius: 20px;
        height: 100%;
        @include flex-center
      }
    }
  }
  &.skill_section {
    .logo_row {
      @include grid-layout;
      grid-template-columns: repeat(6, 1fr);
      row-gap: 0px;
      .logos {
        padding: 20px;
        box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.08);
        border-radius: $border-radius;
        max-height: 200px;
        min-height: 200px;
        @include flex-center;
        transition: all .2s ease-in-out;
        img {
          width: 100px;
          height: 100px;
          object-fit: contain;
        }
        &:nth-child(odd) {
          margin-top: 40px;
        }
        &:hover{
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
          transition: all .2s ease-in-out;
        }
      }
    }
  }
  &.expertise_section {
    background: $bg-color;
    padding: 60px 0px;
    border-radius: 100px 100px 0px 0px;
    p {
      font-size: $font-size-20;
      line-height: $line-height-30;
      color: $text-555;
      max-width: 680px;
      text-align: center;
      margin: 0 auto 40px;
    }
    .logo_row {
      @include grid-layout;
      column-gap: 22px;
      row-gap: 30px;
      margin: 0px;
      .logos {
        height: 100px;
        @include flex-center;
        background: $white-color;
        border-radius: $border-radius;
        img {
          width: 140px;
        }
      }
    }
  }
}

@media only screen and (max-width: $xl-large-1400) {
  .logo_section {
    &.client_section {
      .logo_row {
        .logos {
          padding: 12px;
        }
      }
    }
  }
  .logo_row {
    .logos {
      img {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .logo_section {
    &.skill_section {
      .logo_row {
        .logos {
          min-height: 165px;
        }
      }
    }
  }
}

@media only screen and (max-width: $large-1200) {
  .logo_section {
    h2 {
      margin-bottom: $margin_bottom;
    }
    &.client_section {
      padding: 0px 20px 40px;
    }
    &.skill_section {
      .logo_row {
        grid-template-columns: repeat(4, 1fr);
      }
    }
    &.expertise_section {
      border-radius: 60px 60px 0px 0px;
      p {
        font-size: $font-size-18;
        line-height: normal;
      }
    }
  }
}

@media only screen and (max-width: $sm-tablet-991) {
  .logo_section {
    h2{
      br {
        display: none;
      }
    }
    &.client_section { 
      .logo_row {
        .logos {
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
    &.skill_section {
      .logo_row {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 0;
        .logos {
          min-height: auto;
          &:nth-child(odd) {
            margin-top: 20px;
          }
          img {
            width: 80px;
            height: 80px;
          }
        }
      }
    }
    &.expertise_section { 
      padding: 40px 10px;
      border-radius: 40px 40px 0px 0px;
      p {
        max-width: 100%;
        margin: 0 auto 20px;
      }
    }
  }
}

@media only screen and (max-width: $mobile-767) {
  .logo_section {
    .logo_row {
      .logos {
        img {
          height: 50px;
          object-fit: contain;
        }
      }
    }
    &.skill_section {
      padding: 0px 20px;
      .logo_row {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        .logos {
          &:nth-child(odd) {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $sm-mobile-575) {
  .logo_section {
    &.skill_section {
      .logo_row {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &.expertise_section {  
      border-radius: 30px 30px 0px 0px;
      p {
        font-size: $font-size-16;
      }
      .logo_row {
        .logos {
          height: 80px;
          img {
            width: 120px;
          }
        }
      }
    }
  }
}