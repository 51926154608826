@import "../../utils/var.scss";

.testimonial_section {
    padding-bottom: 60px;
    .row {
      padding: 60px 60px 100px;
      background: $bg-color;
      border-radius: $border-radius;
      .first_column {
        h1 {
          @include second_title;
          margin-bottom: $margin_bottom_35;
        }
        p {
          @include paragraph;
          margin-bottom: $margin_bottom_35;
          color: $text-555;
        }
      }
      .second_column {
        .testimonial-slider {
          background: $white-color;
          padding: 50px;
          margin-left: 90px;
          border-radius: $border-radius;
          box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
          position: relative;
          .carousel {
            position: static;
            .carousel-item {
              img {
                width: 120px;
                height: 120px;
                border-radius: 50%;
              }
              .carousel-caption {
                position: relative;
                right: 0;
                left: 0;
                top: 0;
                color: $text-222;
                padding: 0px;
                margin-top: 28px;
                p {
                  @include paragraph;
                }
                h3 {
                  @include _title;
                  font-size: $font-size-20;
                  margin-bottom: 0px;
                  margin-top: 28px;
                  line-height: 26px;
                }
              }
            }
            .carousel-indicators {
              bottom: -40px;
              overflow: hidden;
              button {
                min-width: 12px;
                max-width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #d9d9d9;
                border: 0px;
                &.active {
                  background: #222;
                }
              }
            }
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: $large-1200) {
    .testimonial_section {
      padding: 0 20px 40px;
      .row {
        padding: 40px 40px 60px;
        .second_column {
          .testimonial-slider {
            padding: 30px;
            margin-left: 40px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $sm-tablet-991) {
    .testimonial_section {
      .row {
        padding: 20px 20px 40px;
        flex-direction: column;
        .first_column {
          text-align: center;
          h1, p {
            margin-bottom: $margin_bottom;
        }
        } 
        .second_column {
          .testimonial-slider {
            margin: 20px 0 0;
            padding: 20px;
            .carousel {
              .carousel-item {
                img {
                  width: 80px;
                  height: 80px;
                }
                .carousel-caption {
                  margin-top: $margin_bottom;
                  h3 {
                    margin-top: $margin_bottom;
                    font-size: $font-size-18;
                  }
                }
              }
            }
          }
        }
      }
    } 
  }

  @media only screen and (max-width: $mobile-767) {
    .testimonial_section {
      .row {
        .second_column {
          .testimonial-slider {
            padding: 10px;
            .carousel  {
              .carousel-item  {
                img {
                  width: 60px;
                  height: 60px;
                }
              }
            }
          }
        }
      }
    }
  }