@import "../../utils/var.scss";

.services_section {
    padding: 0px 0px 160px;
    .row {
      background-color: $bg-color;
      padding: 105px;
      border-radius: $border-radius;
      position: relative;
      .first_column {
        &:before {
          content: "";
          background: url("../../assets/svg/block.svg") no-repeat;
          background-size: 126px;
          width: 126px;
          height: 136px;
          position: absolute;
          left: -55px;
          animation: topbottom 1.5s infinite;
        }
        &:after {
          content: "";
          background: url("../../assets/svg/block.svg") no-repeat;
          background-size: 69px;
          width: 69px;
          height: 74px;
          position: absolute;
          bottom: -30px;
          left: 550px;
          animation: topbottom 1.5s infinite;
        }
        small {
          color: $theme-color;
          font-size: $font-size-20;
          line-height: $font-size-20;
          font-weight: $font-weight-900;
          margin-bottom: 18px;
          display: block;
        }
        h1 {
          @include second_title;
          margin-bottom: $margin_bottom;
        }
        p {
          font-size: $font-size-20;
          line-height: $line-height-30;
          color: $text-000;
          padding-right: 30px;
        }
      }
      .second_column {
        .each_service {
          &:nth-child(1) {
            position: absolute;
            transform: rotate(-5deg);
            transition: all .2s;
            top: -100px;
            right: 70px;
            width: 550px;
            &:hover{
              transform: rotate(0deg);
              transition: all .2s;
            }
          }
          &:nth-child(2) {
            transform: rotate(3deg);
            transition: all .2s;
            &.bg-light-color {
              background: $theme-color;
              &:hover{
                transform: rotate(0deg);
                transition: all .2s;
              }
            }
          }
          &:nth-child(3) {
            position: absolute;
            bottom: -100px;
            width: 550px;
            right: 70px;
            transform: rotate(3deg);
            transition: all .2s;
            &:hover{
              transform: rotate(0deg);
              transition: all .2s;
            }
          }
          @include flex-center;
          padding: 30px;
          border-radius: $border-radius;
          &.bg-light-color {
            background: $bg-color-47;
            outline: 1px dashed #fff;
            outline-offset: -10px;
          }
          img {
            margin-right: 18px;
            width: 90px;
          }
          .content {
            color: $white-color;
            h4 {
              font-size: $font-size-20;
              line-height: $font-size-20;
              font-weight: $font-weight-900;
              margin-bottom: 5px;
            }
            p {
              @include paragraph;
            }
          }
        }
      }
    }
  }
  
@media only screen and (max-width: 1366px) {
  .services_section {
    .row {
      padding: 60px;
      .second_column {
        .each_service {
          padding: 20px;
          img {
            width: 60px;
          }
        }
      } 
    }
  } 
}

@media only screen and (max-width: $large-1200) {
  .services_section {
    padding: 40px 20px;
    .row {
      gap: 40px;
      flex-direction: column;
      padding: 40px;
      .first_column {
        position: relative;
        &:before {
          background-size: 60px;
          width: 70px;
          height: 70px;
          left: -70px;
        }
        &:after {
          left: auto;
          right: -40px;
          width: 50px;
          background-size: 50px;
        }
        p {
          font-size: $font-size-18;
          line-height: normal;
          padding: 0;
        }
      }
      .second_column {
        padding: 0px !important;
        .each_service {
          &:nth-child(1),  &:nth-child(2), &:nth-child(3) {
            position: static;
            width: auto;
            transform: none;
          }
          &:nth-child(2) {
            margin: 20px 0px;
          }
        }
      } 
    }
  }
}

@media only screen and (max-width: $sm-mobile-575) {
  .services_section {
    .row {
      padding: 20px;
      .first_column {
        text-align: center;
        &:before { 
          left: -50px;
        }
        p {
          font-size: $font-size-16;
        }
      } 
      .second_column {
        .each_service {
          gap: 10px;
          flex-direction: column;
          text-align: center;
          img {
            margin: 0;
          }
          .content {
            h4 {
              font-size: $font-size-18;
            }
          }
        }
      }
    }
  } 
}