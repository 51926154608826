@import "../../utils/var.scss";

.portfolio_view {
    padding: 60px 0px;
    .container {
        .all_websites {
            display: flex;
            align-items: self-start;
            .sidebar {
                min-width: 300px;
                margin-right: 30px;
                background: #fff;
                box-shadow: 0px 0px 30px 4px rgba(0, 0, 0, 0.08);
                border-radius: 20px;
                overflow: hidden;
                h3 {
                    padding: 14px 26px;
                    font-size: $font-size-25;
                    font-weight: $font-weight-600;
                    line-height: $line-height-30;
                    color: $white-color;
                    background: $theme-color;
                    text-align: center;
                }
                ul {
                    padding: 20px 20px 10px;
                    li {
                        list-style: none;
                        font-size: 14px;
                        line-height: $line-height-35;
                        display: flex;
                        align-items: center;
                        &:before {
                            content: "";
                            background: url("../../assets/icons/listicon.svg") no-repeat;
                            background-size: 20px;
                            display: block;
                            width: 20px;
                            height: 20px;
                            margin-right: 12px;
                        }
                        a {
                            color: $text-555;
                            text-decoration: none;
                          }
                    }
                }
            }
            .content {
                width: 100%;
            }
        }
    }
    .our_all_work {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: self-start;
        gap: 30px;
        text-align: center;
        &.three_column {
            column-count: 3;
            gap: 30px;
            display: block;
            .item {
                break-inside: avoid-column;
                margin-bottom: 30px;
            }
        }
        &.scroll_animation {
            .img_overlay {
                height: 550px;
                &:hover {
                    background-position: center bottom;
                    transition: 7s ease all;
                    .preview_img {
                        transform: translateY(calc(-100% + 550px));
                        transition: 7s ease all;
                    }
                }
            }
        }
        .item {
            border-radius: $border-radius;
            padding: 10px 10px 20px 10px;
            box-shadow: 0px 0px 30px 4px rgba(0, 0, 0, 0.08);
            .img_overlay {
                position: relative;
                border-radius: $border-radius;
                overflow: hidden;
                .preview_img {
                    object-fit: cover;
                }
                .overlay_icons {
                    opacity: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    text-align: center;
                    @include flex-center;
                    .icons {
                        background: $white-color;
                        height: 60px;
                        width: 60px;
                        border-radius: 50%;
                        @include flex-center;
                    }
                }
                &:hover .overlay_icons {
                    opacity: 1;
                    background: #ff5b5430;
                    width: 100%;
                    height: 100%;
                }
            }
            h3 {
                margin-top: 20px;
                font-size: $font-size-20;
                font-weight: $font-weight-600;
                color: $text-222;
            }
            h5 {
                font-size: $font-size-16;
                font-weight: $font-weight-500;
                line-height: $line-height-30;
                color: $theme-color;
            }
        }
    }
    .pagination {
        margin-top: 62px;
        margin-bottom: 0px;
        .page-item {
            &:last-child {
                .page-link {
                    margin-right: 0px;
                }
            }
            .page-link {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                padding: 0px;
                background: $text-222;
                color: $white-color;
                margin-right: 6px;
                @include flex-center;

                &:focus {
                    box-shadow: none;
                }
                img {
                    width: 20px;
                    &.prev {
                        transform: rotate(180deg);
                    }
                }
            }
            &.active {
                z-index: 0;
                .page-link {
                    background: $theme-color;
                    border: 0px;
                }
            }
        }
    }
}

@media only screen and (max-width: $sm-tablet-991) {    
    .portfolio_view {
        .our_all_work { 
            .item {
                h3 {
                    font-size: 18px;
                }
            }
            &.three_column {
                gap: 20px;
                .item {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 53em) {
    ._2yYOi {
        align-items: start !important;
    }
}

@media only screen and (max-width: $mobile-767) {
    .portfolio_view {
        padding: 40px 5px;
        .our_all_work {
            .item {
                h3 {
                    font-size: 16px;
                }
            }
            &.three_column {
                column-count: 2;
            }
        }
    }
}

@media only screen and (max-width: $sm-mobile-575) {
    .portfolio_view {
        .our_all_work {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
            &.three_column { 
                column-count: 1;
            }
        }
    } 
}