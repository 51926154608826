@import "../../utils/var.scss";

.latest_project_ {
  padding: 60px 0px;
  background: $theme-color;
  .slider_container {
    max-width: 1660px;
    margin-left: auto;
    padding-left: 10px;
    h2 {
      @include second_title;
      color: $white-color;
      margin-bottom: $margin_bottom_35;
    }
    .latest_project_slider {
      .carouselItem {
        padding-right: 40px;
        img {
          border-radius: $border-radius;
        }
        h3 {
          font-size: $font-size-25;
          margin-top: $margin_bottom - 10;
          color: $white-color;
          font-weight: $font-weight-900;
        }
        h5 {
          font-size: $font-size-16;
          margin: 0px;
          color: $white-color;
        }
      }
    }
  }
  @media only screen and (max-width: 1399px) {
    .slider_container {
      max-width: 1270px;
    }
  }
  @media only screen and (max-width: $large-1200) {
    padding: 40px 10px;
    .slider_container {
      max-width: 1080px;
      h2{
        margin-bottom: $margin_bottom;
      }
      .latest_project_slider {
        .carouselItem {
          h3 {
            font-size: $font-size-20;
          }
        }
      }
    }
  }
  @media only screen and (max-width: $sm-tablet-991) {
    .slider_container {
      max-width: 855px;
    }
  }
  @media only screen and (max-width: $mobile-767) {
    .slider_container {
      .latest_project_slider {
        .carouselItem {
          padding-right: 20px;
        }
      }
    }
  }
}
