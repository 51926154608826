@import "../../utils/var.scss";

.top_header {
  background: $theme-color;
  color: $white-color;
  text-align: right;
  padding: 2px 0px;
  font-size: $font-size-15;
}
header {
  position: sticky;
  top: 0;
  z-index: 3;
  background: $bg-color-f9;
  .navbar {
    @include flex-center;
    height: 110px;
  }
}
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .nav-logo {
    img {
      width: 300px;
    }
  }
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    align-items: center;
    margin: 0px;
    .nav-item {
      padding: 0px 15px;
      &.hireme {
        padding-right: 0px;
      }
      .nav-links {
        color: $text-222;
        text-decoration: none;
        height: 100%;
        font-size: $font-size-18;
        position: relative;
        &.active {
          &:after {
            content: "";
            height: 5px;
            width: 100%;
            background: $theme-color;
            display: block;
            margin-top: 5px;
            border-radius: 50px;
            position: absolute;
          }
        }
      }
      .hireme-btn {
        @include header-button;
        &:hover {
          background: $text-000;
        }
      }
    }
  }
}
.nav-icon {
  display: none;
}

@media screen and (max-width: $large-1200) {
  header {
    .navbar {
      height: 90px;
    }
  }
  .nav-container {
    .nav-logo {
      img {
        width: 250px;
      }
    }
    .nav-menu {
      .nav-item {
        padding: 0px 12px;
        .nav-links {
          font-size: $font-size-16;
        }
        .hireme-btn {
          padding: 10px 15px;
        }
      }
    }
  } 
}

@media screen and (max-width: $sm-tablet-991) {
  .top_header {
    font-size: 12px;
    text-align: center;
  }
  header {
    .navbar {
      height: 70px;
    }
  }
  .nav-container {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1px solid #fff;
      position: absolute;
      top: 72px;
      left: -150%;
      opacity: 1;
      transition: all 0.5s ease;
      &.active {
        background: $white-color;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        padding: 4px 12px;
        align-items: self-start;
      }
      .nav-item {
        padding: 0px;
        width: 100%;
        .nav-links {
          padding: 8px 0px;
          width: 100%;
          text-align: left;
          display: block;
          font-size: 15px;
          &::after {
            height: 2px !important;
          }
        }
      }
    }
  }
  .nav-icon {
    display: block;
    cursor: pointer;
    img {
      width: 25px;
    }
  }
  .hireme {
    display: none;
  }
}

@media screen and (max-width: $mobile-767) {
  header {
    .navbar {
      height: 70px;
    }
  } 
  .nav-container  {
    .nav-logo {
      img {
        width: 220px;
      }
    } 
  }
}

