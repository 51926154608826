@import "../../utils/var.scss";

.footer_section {
  padding: 60px 0px 40px;
  background: url(../../assets/footer-bg.png);
  .footer_col {
    display: grid;
    grid-template-columns: .6fr .8fr .4fr;
    h3 {
      font-size: 32px;
      line-height: 40px;
      font-weight: $font-weight-900;
      margin-bottom: 25px;
      color: $white-color;
    }
    ul {
      li {
        font-size: $font-size-16;
        color: $white-color;
        line-height: 42px;
        display: flex;
        align-items: center;
        img {
          width: 25px;
          margin-right: 12px;
          &.white {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
    .freelance {
      display: flex;
      align-items: center;
      gap: 10px;
      .f_logo {
        width: 200px;
      }
      .icons {
        width: 40px;
      }
    }
    .first_column {
      .logo {
        width: 310px;
        margin-bottom: 39px;
      }
      h4 {
        font-size: $font-size-25;
        line-height: normal;
        font-weight: 600;
        margin-bottom: $margin_bottom;
        padding-right: 50px;
        color: $white-color;
      }
    }
    .second_column {
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          flex-basis: calc(100% / 2);
          a {
            color: $white-color;
            text-decoration: none;
            display: flex;
            align-items: center;
          }
          img {
            width: 28px;
            filter: brightness(0) invert(1);
          }
        }
      }
    }
    .third_column {
      margin-top: 70px !important;
      h3 {
        margin-bottom: 10px;
      }
      ol {
        display: inline-flex;
        gap: 30px;
        li {
          img {
            width: 30px;
          }
        }
      }
    }
  }
}

.copyright_section {
  p {
    @include paragraph;
    color: $white-color;
    padding: 5px;
    background: $theme-color;
  }
}

.fixed_element {
    position: fixed;
    right: 0;
    top: 50%;
    p {
      background: $theme-color;
      text-align: center;
      font-size: 12px;
      border-radius: 10px 0 0 10px;
      &:last-child {
        margin-top: 10px;
      }
      a {
        display: block;
        padding: 10px;
        color: $white-color;
        text-decoration: none;
        line-height: normal;
        span {
          display: block;
          font-size: $font-size-16;
          font-weight: $font-weight-600;
        }
        img {
          width: 25px;
        }
      }
    }
}

@media only screen and (max-width: 1366px) {
  .footer_section {
    .footer_col {
      grid-template-columns: .6fr .8fr .5fr;
      h3 {
        font-size: $font-size-25;
        line-height: 30px;
      }
      ul {
        li {
          font-size: $font-size-15;
          line-height: $line-height-35;
          img {
            width: 20px;
            margin-right: 10px;
          }
        }
      }
      .first_column {
        h4 {
          font-size: $font-size-20;
          padding-right: 20px;
        }
        .logo {
          width: 280px;
          margin-bottom: $margin_bottom;
        }
      } 
      .second_column {
        ul {
          li {
            img {
              width: 22px;
            }
          }
        }
      }
      .third_column {
        margin-top: 60px !important;
        ol {
          gap: 20px;
          margin: 0px;
          li {
            img {
              width: 25px;
            }
          }
        }
      }
      .freelance {
        .f_logo {
          width: 150px;
        }
        .icons {
          width: 25px;
        }
      } 
    }
  }
}

@media only screen and (max-width: 991px) {
  .footer_section {
    padding: 40px 0px;
    .footer_col {
      grid-template-columns: 1fr;
      h3 {
        line-height: normal;
        margin-bottom: 10px;
      }
      .first_column {
        h4 {
          font-size: $font-size-18;
          padding-right: 0px;
        }
      }
      .third_column {
        margin-top: 10px !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer_section { 
    .footer_col {
      h3 {
        font-size: 22px;
      }
      .first_column {
        h4 {
          margin-bottom: 10px;
        }
        .logo {
          width: 250px;
        }
      }
      .second_column {
        ul {
          li {
            flex-basis: calc(100% / 1);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .footer_section {
    padding: 30px 20px;
  }
}