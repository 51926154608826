@import "../../utils/var.scss";

.three_things_section {
  padding: 40px 0px 180px;
  h1 {
    @include second_title;
    margin-bottom: 70px;
    text-align: center;
  }
  .theme-color {
    color: $theme-color;
  }
  .each-agency {
    display: flex;
    align-items: self-start;
    border: 1px dashed #ddd;
    border-radius: 20px;
    height: 100%;
    padding: 20px;
    .number {
      background: url("../../assets/svg/num-bg.svg") no-repeat;
      background-size: contain;
      min-width: 110px;
      height: 95px;
      @include flex-center;
      @include second_title;
      font-size: $font-size-50;
      line-height: normal;
      color: $white-color;
      margin-right: 30px;
    }
    .content {
      h3 {
        @include _title;
        font-size: $font-size-30;
        margin-bottom: 10px;
      }
      p {
        @include paragraph;
        color: $text-555;
      }
    }
  }
  &.digital_experts {
    background: $text-222;
    padding: 60px 0px;
    position: relative;
    .container {
      position: relative;
      &:before {
        content: "";
        background: url('../../assets/svg/block.svg') no-repeat;
        background-size: 110px;
        width: 110px;
        height: 120px;
        position: absolute;
        left: 0;
        top: -100px;
        animation: topbottom 1.5s infinite;
      }
      &:after {
        content: "";
        background: url('../../assets/svg/block.svg') no-repeat;
        background-size: 69px;
        width: 69px;
        height: 74px;
        position: absolute;
        right: 0;
        bottom: -100px;
        animation: topbottom 1.5s infinite;
      }
      .spacing {
        &:nth-child(1) {
          padding: 0px 30px 38px 0px;
          border-bottom: 1px dashed #757575;
          border-right: 1px dashed #757575;
        }
        &:nth-child(2) {
          padding: 0px 0px 38px 30px;
          border-bottom: 1px dashed #757575;
        }
        &:nth-child(3) {
          padding: 22px 30px 0px 0px;
          border-right: 1px dashed #757575;
        }
        &:nth-child(4) {
          padding: 22px 0px 0px 30px;
        }
        .each-agency {
          border: 0px;
          border-radius: 0px;
          height: auto;
          padding: 0px;
          .content {
            h3,
            p {
              color: $white-color;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: $xl-large-1400) {
    padding: 40px 0px 160px;
    h1 {
      margin-bottom: 50px;
    }
    .each-agency {
      .number {
        min-width: 90px;
        height: 80px;
        background-size: contain;
        margin-right: 20px;
        font-size: $font-size-50;
        line-height: normal;
      }
      .content {
        h3 {
          font-size: $font-size-30;
          line-height: $line-height-45;
        }
      }
    }
  }
  @media only screen and (max-width: $large-1200) {
    padding: 40px 10px;
    h1 {
      margin-bottom: 30px;
    }
    .each-agency {
      flex-direction: column;
      align-items: center;
      text-align: center;
      .number {
        min-width: 70px;
        height: 60px;
        margin-right: 0;
        font-size: $font-size-30;
      }
      .content {
        margin-top: 10px;
        h3 {
          font-size: $font-size-25;
          line-height: normal;
        }
        p {
          font-size: $font-size-15;
          line-height: normal;
        }
      }
    }
    &.digital_experts {
      padding: 40px 20px;
    }
  }
  @media only screen and (max-width: $sm-tablet-991) {
    .row {
      flex-direction: column;
      gap: 20px;
    }
    &.digital_experts {
      .container {
        &::before {
          width: 80px;
          height: 90px;
          background-size: 80px;
          top: -70px;
        }
        &::after {
          background-size: 50px;
          width: 50px;
          height: 60px;
          bottom: -80px;
        }
        .row {
          flex-direction: row;
          gap: 0;
          .spacing:nth-child(1) {
            padding: 0px 20px 20px 0px;
          }
          .spacing:nth-child(2) {
            padding: 0px 0px 20px 20px;
          }
          .spacing:nth-child(3) {
            padding: 20px 20px 0px 0px;
          }
          .spacing:nth-child(4) {
            padding: 20px 0px 0px 20px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: $mobile-767) {
    .each-agency {
      padding: 10px;
      .number {
        min-width: 60px;
        height: 50px;
        font-size: $font-size-25;
        font-weight: $font-weight-600;
      }
      .content {
        h3 {
          font-size: $font-size-20;
        }
      }
    }
    &.digital_experts {
      .container {
        &::before {
          background-size: 60px;
          height: 70px;
          top: -60px;
          width: 60px;
        }
        .row {
          flex-direction: column;
          gap: 20px;
          .spacing {
            border: 0px;
            border-bottom: 1px dashed #757575;
            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
              padding: 0 0 20px 0;
            }
            &:nth-child(4) {
              padding: 0px;
              border: 0;
            }
          }
        }
      }
    }
  }
}
