@import "../../utils/var.scss";

.making_brand_section {
  background-color: $bg-color;
  background-image: url("../../assets/Tablet_Image_bg.png");
  background-repeat: no-repeat;
  padding: 30px 20px;
  background-size: contain;
  background-position: -100px;
  .brand_first_section {
    img {
      max-width: 710px;
    }
  }
  .brand_second_section {
    h1 {
      @include second_title;
      margin-bottom: $margin_bottom;
    }
    p {
      font-size: 20px;
      line-height: $line-height-30;
      padding-right: 30px;
      color: $text-555;
    }
  }

  @media only screen and (max-width: $xl-large-1400) {
    background-size: 50% 100%;
    .brand_first_section {
      img {
        max-width: 100%;
      }
    }
  }
  @media only screen and (max-width: $large-1200) {
    .brand_second_section {
      h1 {
        margin-bottom: 10px;
      }
      p {
        font-size: 18px;
        line-height: $line-height-26;
        padding-right: 40px;
      }
    }
  }
  @media only screen and (max-width: $tablet-1024) {
    .brand_second_section {
      p {
        font-size: 16px;
        line-height: 20px;
        padding-right: 0px;
      }
    }
  }
  @media only screen and (max-width: $mobile-767) {
    .making_brand_c {
      flex-direction: column;
      gap: 20px;
      .brand_second_section {
        text-align: center;
      }
    }
  }
}
