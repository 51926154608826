@import "../../utils/var.scss";

.work_process_section {
	position: relative;
	padding: 60px 0px;
	h2 {
		@include second_title;
		margin-bottom: 40px;
	}
	p {
		font-size: $font-size-20;
		line-height: $line-height-30;
		color: $text-555;
		max-width: 680px;
		text-align: center;
		margin: 0 auto 90px;
	}
	.work-process {
		position: relative;
		padding: 0px 15px;
		.step-num-box {
			position: relative;
			display: inline-block;
			margin-bottom: 20px;
			.step-icon {
				background: $theme-color;
				width: 100px;
				height: 100px;
				line-height: 100px;
				position: relative;
				border: 3px solid #fafaff;
				border-radius: 50%;
				span {
					&::after {
						border-radius: 50%;
						content: "";
						height: 40px;
						position: absolute;
						width: 40px;
						z-index: -1;
						background: $theme-color;
						height: 25px;
						left: -10px;
						top: 5px;
						width: 25px;
					}
					&::before {
						border-radius: 50%;
						content: "";
						height: 40px;
						position: absolute;
						width: 40px;
						z-index: -1;
						background: #e7a2ac;
						bottom: 0;
						right: -10px;
					}
					img {
						width: 50px;
						filter: brightness(0) invert(1);
					}
				}
			}
			.step-num {
				background: #fff;
				border-radius: 50%;
				box-shadow: 0 2px 8px 0 rgb(224, 224, 224);
				color: $theme-color;
				display: inline-block;
				font-size: 18px;
				font-weight: 600;
				height: 50px;
				right: 0;
				line-height: 55px;
				position: absolute;
				text-align: center;
				top: -20px;
				width: 50px;
			}
		}
		.step-desc {
			h4 {
				font-size: 25px;
				font-weight: 600;
				color: #222;
			}
		}
	}
	#svg-container {
		position: absolute;
		width: 95%;
		left: 0;
		right: 0;
		bottom: -20px;
		z-index: -1;
		margin: 0 auto;
	}
}
@media only screen and (max-width: $large-1200) {
	.work_process_section {
		h2 {
			margin-bottom: $margin_bottom;
		}
		p {
			font-size: $font-size-18;
			line-height: normal;
			margin: 0 auto 60px;
		}
		.work-process {
			.step-desc {
				h4 {
					font-size: $font-size-20;
				}
			}
		}
	}
}
@media only screen and (max-width: $sm-tablet-991) {
	.work_process_section {
		padding: 40px 0px 60px;
		p {
			margin: 0 auto 40px;
		}
		#svg-container {
			width: 100%;
			transform: rotate(90deg);
			top: 50%;
			bottom: auto;
		}
	}
	.md-mt-5 {
		margin-top: 50px !important;
	}
	.step-desc {
		p {
			max-width: 300px;
			margin: 0 auto;
		}
	}
}
@media only screen and (max-width: $mobile-767) {
	.work_process_section {
		padding: 40px 20px;
	}
}
@media only screen and (max-width: $sm-mobile-575) {
	.work_process_section {
		p {
			font-size: $font-size-16;
		}
	} 
}