@import "../../utils/var.scss";

.work_category_section {
  padding: 60px 0px;
  .work_category_row {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    .each_cat {
      flex-basis: calc(50% - 15px);
      background: $bg-color;
      border-radius: $border-radius;
      overflow: hidden;
      width: 100%;
      h3 {
        padding: 10px;
        font-size: $font-size-20;
        font-weight: $font-weight-600;
        line-height: $line-height-30;
        color: $white-color;
        background: $theme-color;
        text-align: center;
      }
      .category_list {
        padding: 20px 20px 15px;
        overflow-y: auto;
        ul {
          padding: 0px;
          margin: 0px;
          display: flex;
          flex-wrap: wrap;
          column-gap: 20px;
          li {
            list-style: none;
            font-size: 14px;
            line-height: $line-height-35;
            display: flex;
            align-items: center;
            flex-basis: calc(50% - 10px);
            &:before {
              content: "";
              background: url("../../assets/icons/listicon.svg") no-repeat;
              background-size: 20px;
              display: block;
              width: 20px;
              height: 20px;
              margin-right: 12px;
            }
            a {
              color: $text-555;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $large-1200) {
  .work_category_section {
    padding: 40px 20px;
    .work_category_row {
      flex-wrap: wrap;
      .each_cat {
        flex-basis: calc(50% - 15px);
      }
    }
  }
}

@media only screen and (max-width: $sm-tablet-991) {
  .work_category_section {
    .work_category_row {
      gap: 20px;
      .each_cat {
        flex-basis: calc(50% - 10px);
      }
    }
  }
}

@media only screen and (max-width: $mobile-767) {
  .work_category_section {
    .work_category_row {
      .each_cat {
        flex-basis: calc(100%);
        h3 {
          padding: 8px;
          font-size: $font-size-18;
          line-height: normal;
        }
        .category_list {
          padding: 10px;
          ul {
            li {
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $sm-mobile-575) {
  .work_category_section {
    .work_category_row {
      .each_cat {
        .category_list {
          max-height: 225px;
          ul {
            li {
              flex-basis: 100%;
            }
          }
        }
      }
    } 
  } 
}