@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin bg-radius {
  background: $bg-color;
  border-radius: $border-radius;
}

@mixin header-button {
  background-color: $theme-color;
  border-radius: 50px;
  margin: 0;
  font-size: $font-size-16;
  font-weight: $font-weight-600;
  color: $white-color;
  padding: 12px 20px;
  white-space: nowrap;
  @media only screen and (max-width: $xl-large-1400) {
    
  }
}

@mixin second-button {
  background-color: $theme-color;
  border-radius: 50px;
  border: 0px;
  font-size: $font-size-20;
  font-weight: $font-weight-600;
  color: $white-color;
  padding: 14px 30px;
  @media only screen and (max-width: $xl-large-1400) {
    font-size: $font-size-20 - 2px;
  }
  @media only screen and (max-width: $large-1200) {
    font-size: $font-size-20 - 4px;
  }
  @media only screen and (max-width: $mobile-767) {
    padding: 12px 20px;
  }
}

@mixin _title {
  font-family: $font-family;
  font-weight: $font-weight-900;
  color: $text-222;
}

@mixin large_title {
  font-family: $font-family;
  font-weight: $font-weight-900;
  color: $theme-color;
  font-size: 250px;
  line-height: 250px;
  text-align: center;
  text-transform: uppercase;
  margin: 0px;
  @media only screen and (max-width: $xl-large-1400) {
    font-size: 210px;
    line-height: 210px;
  }
  @media only screen and (max-width: $large-1200) {
    font-size: 180px;
    line-height: 180px;
  }
  @media only screen and (max-width: $tablet-1024) {
    font-size: 140px;
    line-height: 140px;
  }
  @media only screen and (max-width: $sm-tablet-991) {
    font-size: 100px;
    line-height: 100px;
  }
  @media only screen and (max-width: $mobile-767) {
    font-size: 80px;
    line-height: 80px;
  }
  @media only screen and (max-width: $sm-mobile-575) {
    font-size: 60px;
    line-height: 60px;
  }
}

@mixin banner_title {
  @include _title;
  font-size: $font-size-90;
  line-height: $line-height-100;
  @media only screen and (max-width: $xl-large-1400) {
    font-size: $font-size-90 - 15px;
    line-height: $line-height-100 - 20px;
  }
  @media only screen and (max-width: $large-1200) {
    font-size: $font-size-90 - 30px;
    line-height: $line-height-100 - 35px;
  }
  @media only screen and (max-width: $tablet-1024) {
    font-size: $font-size-90 - 35px;
    line-height: $line-height-100 - 40px;
  }
  @media only screen and (max-width: $sm-tablet-991) {
    font-size: $font-size-90 - 40px;
    line-height: $line-height-100 - 45px;
  }
  @media only screen and (max-width: $mobile-767) {
    font-size: $font-size-90 - 50px;
    line-height: $line-height-100 - 55px;
  }
}

@mixin second_title {
  @include _title;
  font-size: $font-size-50;
  @media only screen and (max-width: $xl-large-1400) {
    font-size: $font-size-45;
  }
  @media only screen and (max-width: $large-1200) {
    font-size: $font-size-40;
  }
  @media only screen and (max-width: $sm-tablet-991) {
    font-size: $font-size-35;
  }
  @media only screen and (max-width: $mobile-767) {
    font-size: $font-size-30;
  }
  @media only screen and (max-width: $sm-mobile-575) {
    font-size: $font-size-25;
  }
}

@mixin paragraph {
  font-family: $font-family;
  font-weight: $font-weight-400;
  font-size: $font-size-16;
  line-height: $line-height-26;
  @media only screen and (max-width: $sm-tablet-991) {
    font-size: $font-size-15;
    line-height: $line-height-20;
  }
}

@mixin small_heading {
  font-size: $font-size-30;
  font-family: $font-family;
  font-weight: $font-weight-900;
  color: $text-222;
  line-height: normal;
  text-align: center;
  @media only screen and (max-width: $large-1200) {
    font-size: $font-size-25;
  }
  @media only screen and (max-width: $sm-tablet-991) {
    font-size: $font-size-20;
  }
}
