@import "../../utils/var.scss";

.work_section {
  background: $text-222;
  padding: 60px 0px;
  .work_titles {
    color: $white-color;
    .first_column {
      margin-bottom: $margin_bottom_40;
      h1 {
        @include second_title;
        color: $theme-color;
        margin-right: auto;
      }
      h2 {
        @include small_heading;
        color: $white-color;
      }
    }
  }
  .projects_ {
    margin-top: 32px;
    ._column {
      padding: 0px 50px 0px 0px;
      &:nth-child(2n + 2) {
        padding: 0px 0px 0px 50px;
      }
      &:nth-child(2n + 1) {
        margin-top: 80px;
      }
    }
    img {
      width: 100%;
      border-radius: $border-radius;
      height: auto;
    }
    h4 {
      font-size: $font-size-25;
      font-weight: $font-weight-900;
      color: $white-color;
      text-decoration: none;
      margin-top: $margin_bottom - 10;
      text-align: center;
    }
  }
}

@media only screen and (max-width: $large-1200) {
  .work_section {
    padding: 40px 20px;
    .work_titles {
      .first_column {
        margin-bottom: $margin_bottom;
      }
    }
    .projects_ {
      ._column {
        &:nth-child(2n + 1) {
          margin-top: 40px;
        }
        h4 {
          font-size: $font-size-20;
        }
      }
    }
  }
}

@media only screen and (max-width: $sm-tablet-991) {
  .work_section {
    .projects_ {
      margin-top: $margin_bottom;
      ._column {
        &:nth-child(2n + 1) {
          margin-top: 30px;
          padding: 0px 20px 0px 0px;
        }
        &:nth-child(2n + 2) {
          padding: 0px 0px 0px 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-767) {
  .work_section {
    .work_titles {
      .first_column {
        margin: 0px;
      }
    }
    .projects_ {
      margin-top: 10px;
      ._column { 
        margin-top: 30px !important;
        padding: 0px !important;
        h4 {
          font-size: $font-size-18;
        }
      }
    }
  }
}