@import "./mixins.scss";
@import "./breakpoint.scss";
@import "./color.scss";

$font-family: "Mulish";

$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-900: 900;

$font-size-90: 90px;
$font-size-70: 70px;
$font-size-60: 60px;
$font-size-55: 55px;
$font-size-50: 50px;
$font-size-45: 45px;
$font-size-40: 40px;
$font-size-35: 35px;
$font-size-30: 30px;
$font-size-25: 25px;
$font-size-20: 20px;
$font-size-18: 18px;
$font-size-16: 16px;
$font-size-15: 15px;

$line-height-16: 16px;
$line-height-20: 20px;
$line-height-26: 26px;
$line-height-35: 35px;
$line-height-30: 30px;
$line-height-40: 40px;
$line-height-50: 50px;
$line-height-45: 45px;
$line-height-70: 70px;
$line-height-80: 80px;
$line-height-100: 100px;

$margin_bottom: 20px;
$margin_bottom_30: 30px;
$margin_bottom_35: 35px;
$margin_bottom_40: 40px;

$border-radius: 20px;
