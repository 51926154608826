@import "../../utils/var.scss";

#reviews_section {
    padding: 60px 0px;
    .columns_ {
        display: grid;
        grid-template-columns: .7fr 1fr;
        gap: 40px;
        .left_column {
            small {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 16px;
                font-weight: 600;
                color: #cf2129;
                &:before {
                    content: '';
                    width: 30px;
                    display: block;
                    height: 2px;
                    background: #cf2129;
                }
            }
            h1 {
                @include second_title;
                line-height: 50px;
                margin: 20px 0px;
            }
        }
        .right_column {
            .reviews_ {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 30px;
                .review {
                    background: #f7f7f7;
                    padding: 20px;
                    border-radius: 20px;
                    box-shadow: 0 0 25px rgba(0, 0, 0, .05);
                    .des_ {
                        font-size: 15px;
                        display: -webkit-box;
                        max-width: 500px;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                    .info {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-top: 20px;
                        img {
                            width: 30px;
                            border-radius: 50%;
                        }
                        h5 {
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $sm-tablet-991) {
    #reviews_section {
        padding: 40px 5px;
        .columns_ {
            grid-template-columns: 1fr;
            .right_column {
                .reviews_ {
                    gap: 20px;
                }
            }
        }
    }
}

@media only screen and (max-width: $mobile-767) {
    #reviews_section {
        .columns_ {
            .left_column {
                h1 {
                    margin: 10px 0px;
                    line-height: normal;
                }
            }
            .right_column {
                .reviews_ {
                    grid-template-columns: repeat(1, 1fr);
                    .review {
                        padding: 15px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}